.textCard {
    text-align: center;
}

.textCardRight {
    text-align: right;
}

.subsButton {
    font-size: 13px;
    padding-left: 20px;
    padding-right: 20px;
}

.subsButton2 {
    font-size: 12px;
    padding-left: 30px;
    padding-right: 30px;
    white-space: nowrap;
    font-weight: 400;
}

.numberCred {
    font-size: 54px;
    font-weight: 600;
}

.sizeCard {
    min-height: 130px;
    padding-left: 40px;
    padding-right: 40px;
}

.list > li {
    border-radius: 15px;
    background: #fff;
}

.whiteButton {
    color: #361A52;
    background-color: #fff;
    border: 1px solid #361A52;
    border-radius: 8px;
    padding: 8px 35px;
}

.whiteButton:hover {
    background-color: #361A52;
    color: #fff;
}

.whiteButton > .filterNum {
    border-radius: 20px;
    background-color: #361A52;
    color: #FFF;
    transition: 0.3s;
}

.whiteButton:hover > .filterNum {
    background-color: #FFF;
    color: #361A52;
}

.purpleButton {
    color: #fff;
    background-color: #361A52;
    border: 1px solid #361A52;
    border-radius: 8px;
    padding: 8px 35px;
}
