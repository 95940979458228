.message {
    background-color: rgba(3, 24, 81, 0.05);
    border-radius: 5px;
    padding: 5px 11px;
}

.message > p {
    font-size: 14px;
}

.messageVerify {
    padding: 5px 11px;
    font-size: 14px;
    text-align: justify;
}

.title {
    color: #131f3e;
    font-size: 18px;
    font-weight: 700;
}

.titleVerify {
    color: #131f3e;
    font-size: 22px;
    font-weight: 700;
}

.haveAcc {
    font-size: 14px;
    font-weight: 500 !important;
}

.login {
    font-size: 14px;
    font-weight: 600;
}

.checkList {
    margin: 0;
    padding-left: 0rem;
    padding-top: 1rem;
}

.checkList li {
    position: relative;
    list-style-type: none;
    padding-left: 1.2rem;
    margin-bottom: 0.3rem;
}

.checkList li:before {
    content: "";
    display: block;
    position: absolute;
    left: 0;
    width: 5px;
    height: 11px;
    border-width: 0 2px 2px 0;
    border-style: solid;
    border-color: #361A52;
    transform-origin: bottom left;
    transform: rotate(45deg);
}
