.backWhiteDet {
    background-color: white;
    padding: 15px 25px;
    border-radius: 10px;
    border: 1px solid #4a22d4
}

.backYellowConv {
    background-color: var(--CIT020);
    padding: 15px 25px;
}

@media only screen and (max-width: 1200px) {
    .backYellowConv {
        padding: 15px 20px;
    }
}

.backGray {
    background-color: #FFF;
    padding: 15px 25px;
}

@media only screen and (max-width: 1200px) {
    .backGray {
        padding: 15px 20px;
    }
}

.backWhite2 {
    background-color: #FFF;
    padding: 15px 25px;
}

@media only screen and (max-width: 1200px) {
    .backWhite2 {
        padding: 15px 20px;
    }
}

.backPurple2 {
    background-color: var(--G020);
    padding: 15px 25px;
}

@media only screen and (max-width: 1200px) {
    .backPurple2 {
        padding: 15px 20px;
    }
}

.backPurpleInc {
    background-color: var(--G020);
    padding: 15px 25px;
}

@media only screen and (max-width: 1200px) {
    .backPurpleInc {
        padding: 15px 20px;
    }
}

.backGreenConv {
    background-color: var(--E020);
    padding: 15px 25px;
}

@media only screen and (max-width: 1200px) {
    .backGreenConv {
        padding: 15px 20px;
    }
}

.backRed {
    background-color: var(--C010);
    padding: 15px 25px;
}

@media only screen and (max-width: 1200px) {
    .backRed {
        padding: 15px 20px;
    }
}

.title {
    padding: 30px;
}

.workbookContainer table {
    
    max-height: 100px;
}

.workbookContainer table {
    border-collapse: collapse;
    width: 100%;
}

.workbookContainer table th,
.workbookContainer table td {
    border: 1px solid #ddd;
    padding: 8px;
    text-align: left;
}

.workbookContainer table th {
    background-color: #f2f2f2;
    font-weight: bold;
}

.workbookContainer table tr:nth-child(even) {
    background-color: #f2f2f2;
}

/*.backPurple { background-color: #4a22d4;
    padding: 15px 30px;
    margin: 0 35px;
    border: 2px solid #4a22d4;
    border-radius: 10px;
    height: 89px;
    margin-top: auto;
    margin-bottom: auto;
}*/

.backPurple {
    background-color: #4822d452;
    padding: 15px 30px;
    margin: 0 35px;
    border-radius: 10px;
    height: 100px;
    margin-top: auto;
    margin-bottom: auto;
}

/* .newTitle {
    color: white;
} */

.newTitle {
    color: black;
}

.newTitleBold {
    color: white;
    font-weight: 700;
}

.backMiddleConvert {
    background-color: #4822d452;
    padding: 6px 10px;
    border-radius: 10px;
    color: black;
}

.backMiddle {
    background-color: rgba(255, 255, 255, 0.432);
    padding: 6px 15px;
    border-radius: 10px;
}

.backGreen {
    background-color: #9ed4b0;
    padding: 15px 30px;
    margin: 0 35px;
    border-radius: 10px;
}

.backWhite {
    background-color: rgba(0, 148, 59, 1);
    color: #fff;
    padding: 5px 10px;
    border-radius: 10px;
    max-width: 130px;
    margin-top: 5px;
    display: flex;
    justify-content: center;
}

.backYellow {
    background-color: #fedbb6;
    padding: 3px 10px 2px 10px;
    border-radius: 10px;
}

.creditsText {
    color: rgba(19, 31, 62, 1);
    padding-top: 5px;
    text-align: center;
}

.titleBlue {
    color: rgba(19, 31, 62, 1);
    font-size: 18px;
    font-weight: 600;
}

.textBlue1 {
    color: rgba(19, 31, 62, 1);
    font-size: 16px;
    font-weight: 600;
}

.textBlue2 {
    color: rgba(19, 31, 62, 1);
    font-size: 16px;
    font-weight: 600;
}

.textBlue3 {
    color: rgba(19, 31, 62, 1);
    font-size: 13px;
}

.textBlue4 {
    color: rgba(19, 31, 62, 1);
    font-size: 14px;
}

.buttonDownload {
    background-color: #b3fffd;
    color: rgba(19, 31, 62, 1);
    font-size: 15px;
    padding: 8px 15px;
    justify-content: space-between;
}

.buttonDownload:hover {
    background-color: #98feff;
    color: rgba(19, 31, 62, 1);
}

.buttonReset {
    background-color: rgba(0, 255, 255, 1);
    color: rgba(19, 31, 62, 1);
    font-size: 16px;
    padding: 8px 15px;
    display: flex;
    justify-content: space-between;
    width: 100%;
    padding: 12px 24px;
}

.buttonReset:hover {
    background-color: rgba(0, 255, 255, 1);
    color: rgba(19, 31, 62, 1);
}

.backRed {
    background-color: var(--C010);
    padding: 15px 30px;
    margin: 0 35px;
    border-radius: 10px;
}

.textBlue2Alt {
    color: rgba(19, 31, 62, 1);
    font-size: 16px;
    font-weight: 500;
    margin-bottom: 10px;
}

.backYellow2 {
    background-color: rgba(255, 153, 0, 0.35);
    padding: 15px 30px;
    margin: 0 35px;
    border-radius: 10px;
}

.backYellow3 {
    background-color: rgba(255, 178, 63, 1);
    color: #fff;
    padding: 5px 10px;
    border-radius: 10px;
    max-width: 130px;
    margin-top: 5px;
}

.buttonStopped {
    background-color: rgba(217, 217, 217, 1);
    color: rgba(19, 31, 62, 1);
    font-size: 20px;
    padding: 8px 15px;
    display: flex;
    justify-content: space-between;
    width: 100%;
    cursor: none;
    pointer-events: none;
}

.buttonStopped:hover {
    background-color: rgba(217, 217, 217, 1);
    color: rgba(19, 31, 62, 1);
}
