.dropzone {
    padding: 15px 20px;
    background-color: #F8F7F9;
    border: 1px solid #E0E0E0;
}

.dropzone:hover {
    cursor: default;
    border: 1px solid #c9c9c9;
}

.dropzoneActive {
    background-color: #F8F7F9;
    border: 1px solid #E0E0E0;
    padding: 15px 20px;
}

.dropzoneActive:hover {
    cursor: pointer;
    border: 1px solid #c9c9c9;
}

.boxFormat {
    border-radius: 20px;
    background-color: var(--V010);
    padding: 2px 10px;
}

.boxFormat > p {
    color: var(--V090);
    font-size: 12px;
}