.kiiperLogo {
    cursor: pointer;
    padding-left: 10px;
}

.kiiperLogoMobile {
    cursor: pointer;
    margin: 15px 0 10px 0;
}

.credits {
    display: flex;
    padding: 3px 7px 5px 3px;
    height: 42px;
    border-radius: 5px;
    cursor: pointer;
}

.mobileCredits {
    padding: 3px 7px 5px 3px;
    height: 42px;
    border-radius: 5px;
    cursor: pointer;
    width: 100%;
}

.profile {
    background-color: #d9d9d9;
    border-radius: 50%;
    width: 57px;
    color: #000000;
}

.newCompany {
    display: flex;
    border: 1px dotted #4a22d4;
    color: #4a22d4;
}

.filterNum {
    border-radius: 20px;
    background-color: #4a22d4;
    color: #fff;
    transition: 0.3s;
}
