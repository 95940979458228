.background {
    background: linear-gradient(
        to bottom,
        #210040 0%,
        #230040 41%,
        #2b0141 55%,
        #390243 65%,
        #4b0445 73%,
        #620647 80%,
        #7f094b 86%,
        #a10d4e 91%,
        #ca1153 96%,
        #ec1457 100%
    );
    height: 100vh;
    display: flex;
    align-items: center;
}

@media only screen and (max-width: 600px) {
    .svgcontainer {
        position: absolute;
        top: 0;
        left: 15%;
    }

    .svgcontainer svg {
        height: 170px;
    }

    .logo {
        cursor: pointer;
        width: 200px;
        height: 90px;
    }

    .logocontainer {
        position: absolute;
        top: 11%;
        right: 25%;
    }
}

@media only screen and (min-width: 600px) {
    .svgcontainer {
        position: absolute;
        top: 0;
        left: 30%;
    }

    .logo {
        cursor: pointer;
        width: 228px;
        height: 95px;
    }

    .logocontainer {
        position: absolute;
        top: 11%;
        right: 14%;
    }
}

.subtitle {
    color: #ffffff;
    text-align: center;
    padding-bottom: 30px;
    font-size: 16px;
}
