.topHeader {
    max-width: 700px;
    height: 800px;
}

.topHeader > .purpleBack {
    padding: 30px 40px;
}

.purpleBack {
    background-color: #131f3e;
}

.card {
    width: 45px;
    height: 45px;
}

.whiteBack {
    background-color: white;
    padding: 30px 40px 40px 40px;
}

.greyBack {
    background: rgba(230, 230, 230, 0.36);
    padding: 20px 30px;
}

.kiiperLogo {
    width: 150px;
}

.divider {
    background-color: #0ff;
    height: 35px;
}

.whiteText {
    color: white;
    padding-left: 10px;
}

.whiteText2 {
    color: white;
    padding-left: 20px;
}

.whiteTextAll {
    color: white;
    width: 150px;
    text-align: right;
}

.whiteTextAll p {
    line-height: 1.8;
}

.status {
    background-color: #00b147;
    padding-left: 20px;
    padding-right: 20px;
    margin-top: 5px;
}

.status2 {
    background-color: #00b147;
    font-size: 12px;
}

.borderGrey {
    margin-top: 15px;
    padding: 20px 30px;
    border: 1px solid rgba(230, 230, 230, 0.36);
}
.borderGrey2 {
    padding: 20px 30px;
    border: 1px solid rgba(230, 230, 230, 0.36);
}

.spacingTot {
    text-align: right;
}

.spacingTot b{
    padding-right: 30px;
}

.boxPay {
    background-color: rgba(19, 31, 62, 0.03);
    padding: 14px 20px;
    border-radius: 10px;
    margin-bottom: 15px;
}

.statusPending {
    background-color: #FFB23F;
    padding-left: 20px;
    padding-right: 20px;
    margin-top: 5px;
}