.subheader {
    background-color: white;
    padding: 30px;
}

@media (min-width: 800px) {
    .subheader {
        background-color: white;
        padding: 15px 70px;
    }
}

.spacing {
    padding: 30px;
}

@media (min-width: 800px) {
    .spacing {
        padding: 40px 70px;
    }
}

.breadcrumbs {
    font-size: 24px;
    font-weight: 600;
}