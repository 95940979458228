.card {
    width: 400px;
    padding: 30px;
    border-radius: 0;
    box-shadow: none;
}

@media only screen and (max-width: 600px) {
    .card {
        width: 100%;
        padding: 10px;
        border-radius: 0;
        box-shadow: none;
    }
}
