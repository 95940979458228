.active {
    border-radius: 5px;
    border: 1px solid #4a22d4;
    padding: 3px 7px;
}

.active > p {
    color: #4a22d4;
    font-size: 10px;
}

.new {
    border-radius: 5px;
    border: 1px solid #B3B3B3;
    padding: 3px 7px;
}

.new > p {
    color: #B3B3B3;
    font-size: 12px;
}

.toolTip {
    font-size: 12px;
}

.toolTip > span {
    color: var(--S100);
}