.cardContent {
    border-radius: 20px;
    padding: 35px;
    background-color: #f8f8f9;
    box-shadow: none;
}

.purpleButton {
    background-color: #4a22d4;
    border: 1px solid #4a22d4;
    color: #fff;
    font-weight: bold;
    border-radius: 10px;
    text-align: left;
}

.purpleButton:hover {
    background-color: #ffffff;
    color: #4a22d4;
}

.combineBtn {
    background-color: #fff;
    color: #4a22d4;
    border: 1px solid #4a22d4;
    margin-right: 15px;
}

.combineBtnClicked {
    background-color: #4a22d4;
    color: #fff;
    margin-right: 15px;
}

.combineBtnClicked:hover {
    background-color: #3B1BA9;
    color: #fff;
    margin-right: 15px;
}
