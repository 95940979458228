.cardItems {
    border: 2.5px solid #131f3e;
    border-radius: 10px;
    padding: 20px 15px;
    position: relative;
}

.cardItemsPin {
    border: 3px solid var(--V100);
    border-radius: 10px;
    padding: 20px 15px;
    position: relative;
}

.cardItemsPlan {
    border: 1px solid var(--V100);
    border-radius: 10px;
    padding: 20px 30px;
    position: relative;
}

.imagePlan {
    height: 100px;
    width: 100px;
}

.description > p {
    line-height: 2;
}

.description2 > p {
    line-height: 2;
}

.description2 {
    padding-left: 70px;
}

.chipCust {
    color: white;
    background-color: var(--E100);
    font-size: 12px;
    padding: 5px;
    border: none;
}

.chipCustOut {
    height: 15px;
    color: var(--E100);
    border: 2px solid var(--E100);
    font-size: 10px;
    padding: 3px;
    font-weight: 600;
}

.chipCustCancel {
    color: white;
    background-color: #FF5E77;
    font-size: 12px;
    padding: 5px;
    border: none;
}

.chipCustCancelOut {
    height: 15px;
    color: #F31212;
    border: 2px solid #F31212;
    font-size: 10px;
    padding: 3px;
    font-weight: 600;
}

.circulo {
    position: absolute;
    top: 0;
    right: 0;
    width: 100px; /* Ajusta el tamaño del círculo según tus necesidades */
    height: 100px; /* Ajusta el tamaño del círculo según tus necesidades */
    background-color: #00ffff; /* Puedes establecer el color de fondo que desees */
    clip-path: ellipse(36% 25% at 100% 2%); /* Esto recorta un tercio del círculo */
}

.numero {
    position: absolute;
    top: 0;
    right: 0;
    width: 23px; /* Ajusta el tamaño del círculo según tus necesidades */
    height: 20px;
    z-index: 999;
}
.numero > p {
    font-weight: 600;
}

.textCenter > div > p {
    text-align: justify;
    font-size: 14px;
}