:root {
    --V100: #20003f;
    --V090: #361a52;
    --V080: #4d3365;
    --V070: #634d79;
    --V060: #79568c;
    --V050: #8f809f;
    --V040: #a699b2;
    --V030: #bcb3c5;
    --V020: #d2ccd9;
    --V010: #e9e6ec;
    --V003: #f8f7f9;
    
    --C100: #ed1556;
    --C090: #ee2c66;
    --C080: #ef4277;
    --C070: #f05987;
    --C060: #f26f97;
    --C050: #f286a7;
    --C040: #f8a1bb;
    --C030: #FAB9CC;
    --C020: #FBD0DD;
    --C010: #FDE8EE;
    --C003: #FEF8FA;

    --P100: #3B237B;
    --P020: #d8d3e5;
  
    --G100: #9470ee;
    --G020: #dccfff;
  
    --A100: #33b1ff;
    --A020: #b8e4ff;
  
    --E100: #47f3c5;
    --E020: #d8f5ee;
  
    --CIT100: #ffff9e;
    --CIT020: #fff5e3;
  
    --H100: #427878;
    --H020: #ccd5d4;
  
    --O100: #ff966b;
    --O020: #ffdbc6;
  
    --B100: #f35dab;
    --B020: #f9cddd;
  
    --D100: #caef70;
    --D020: #e6f7c0;
  
    --S100: #90d2ff;
    --S020: #d7edff;
  }


body {
    margin: 0;
    height: 100%;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

html, body, #root {
    height: 100%;
    margin: 0;
    padding: 0;
}

.Container-wrapper {
    background-color: var(--V003) !important;
}

.MuiTable-root {
    table-layout: auto !important;
}

.slick-prev:before,
.slick-next:before {
    color: var(--V090) !important;
}

.single-slide .slick-track {
    margin-left: 0;
}

.toolbarMain {
    padding: 20px 20px 20px 10px;
    height: 80px;
    /* box-shadow: 5px 5px 15px rgba(0, 0, 0, 0.05); */
}

.MuiInputLabel-root.Mui-error {
    color: var(--C090) !important;
}

.MuiOutlinedInput-root.Mui-error .MuiOutlinedInput-notchedOutline {
    border-color: var(--C090) !important;
    background: rgba(255, 89, 104, 0.1) !important;
}

.MuiTableBody-root .MuiTableRow-root .TableStubCell-cell {
    background-color: var(--V003);
}

.MuiTableRow-root .TableStubCell-cell {
    border-bottom: 5px solid #fff;
}

/* Customizing the tooltip appearance */
.introjs-tooltip {
    border-radius: 20px !important;
    min-width: 300px !important;
    max-width: 420px !important;
}

.introjs-tooltip-header {
    display: flex;
    padding: 20px 30px 0px 30px !important;
}

.introjs-skipbutton {
    background-color: #c6c9d1;
    border-radius: 20px;
    width: 25px !important;
    height: 25px !important;
    line-height: 25px !important;
    position: relative !important;
    color: white !important;
}

.introjs-skipbutton:hover {
    background-color: #b2b4bc;
}

/* Customizing the tooltip title */
.introjs-tooltip-title {
    color: var(--V090) !important;
    font-family: "" !important;
}

/* Customizing the tooltip text */
.introjs-tooltiptext {
    padding: 20px 30px 5px 30px !important;
    font-family: Gantari !important;
}

.introjs-bullets {
    padding: 0px !important;
}

/* Customizing the navigation buttons */
.introjs-tooltipbuttons {
    border-top: none !important;
    padding: 10px 30px 20px 30px !important;
}

.introjs-button {
    border-radius: 10px !important;
    text-shadow: none !important;
    font-weight: 600;
    font-family: Gantari !important;
}

.introjs-prevbutton {
    background-color: white !important;
    border: none !important;
    color: var(--V090) !important;
}

.introjs-prevbutton:hover {
    background-color: rgba(74, 34, 212, 0.04) !important;
}

.introjs-nextbutton {
    color: #fff !important;
    background-color: var(--V090) !important;
}

.introjs-nextbutton:hover {
    background-color: #2A1940 !important;
}

.introjs-disabled {
    color: #ccc !important;
}

.introjs-disabled:hover {
    background-color: white !important;
}

/* Customizing the highlighted element */
/* .introjs-highlight {
    box-shadow: 0 0 0 5px rgba(0, 123, 255, 0.5) !important;
} */

/* Customizing the helper layer */
/* .introjs-helperLayer {
    background: rgba(0, 0, 0, 0.5) !important;
} */

/* Nav bar option selected */
.navBarOptionSelected {
    background-color: var(--V010) !important;
    border-top-right-radius: 8px;
    border-bottom-right-radius: 8px;
    margin-right: 5px;
}

.react-pdf__Page__canvas {
    width: 100% !important;
}


.textLayer {
    width: auto !important;
}

.MuiOutlinedInput-root {
    height: 48px;
}