.title {
    color: #131f3e;
    font-size: 22px;
    font-weight: 700;
}

.haveAcc {
    font-size: 14px;
    font-weight: 700;
}

.login {
    font-size: 14px;
}
