.list > li {
    border-radius: 15px;
    background: #fff;
    margin-bottom: 5px;
}

.lastTime {
    color: #6d6d6d;
    text-align: right;
    font-size: 14px;
}

.title {
    padding: 5px 5px 5px 20px;
}

.active {
    border-radius: 5px;
    border: 1px solid #4a22d4;
    padding: 5px 15px;
}

.active > p {
    color: #4a22d4;
    font-size: 12px;
}

.new {
    border-radius: 5px;
    border: 1px solid #B3B3B3;
    padding: 5px 15px;
}

.new > p {
    color: #B3B3B3;
    font-size: 12px;
}

.activeIcon {
    font-size: 14px;
    color: #4a22d4;
}

.newIcon {
    font-size: 14px;
    color: #B3B3B3;
}

.admin {
    border-radius: 5px;
    background: var(--G020);
    padding: 5px 15px;
}

.admin > p {
    color: var(--V090);
    font-size: 12px;
}

.super {
    border-radius: 5px;
    background: #FFB23F;
    padding: 5px 15px;
}

.super > p {
    color: #fff;
    font-size: 12px;
}

.colab {
    border-radius: 5px;
    background: var(--A020);
    padding: 5px 15px;
}

.colab > p {
    color: var(--V090);
    font-size: 12px;
}

.espect {
    border-radius: 5px;
    background: rgba(230, 230, 230, 0.50);
    padding: 5px 15px;
}

.espect > p {
    color: #131f3e;
    font-size: 12px;
}

.temporary {
    border-radius: 5px;
    background: #FFF;
    border: 1px solid #FF007A;
    padding: 4px 15px;
}

.temporary > p {
    color: #FF007A;
    font-size: 12px;
}
