.search div{
    height: 35px;
    font-size: 12px;
    margin: 15px;
    max-width: 250px;
    background: rgba(0, 0, 0, 0.05);
    border-radius: 10px
};

.menuItemBold > text {
    font-weight: 700;
}
