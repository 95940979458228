.content {
    border-radius: 24px;
    padding: 30px 70px;
    margin-left: auto;
    margin-right: auto;
    margin-top: 30px;
    margin-bottom: auto;
    box-shadow: 5px 5px 15px rgba(0, 0, 0, 0.05);
}

@media only screen and (max-width: 600px) {
    .content {
        width: 100%;
        margin-left: 35px;
        margin-right: 35px;
    }
}

.content2 {
    border-radius: 24px;
    padding: 30px 0px;
    margin-left: auto;
    margin-right: auto;
    margin-top: auto;
    margin-bottom: auto;
    box-shadow: none;
    background-color: #F7F8F9;
}

@media only screen and (max-width: 600px) {
    .content2 {
        width: 100%;
        margin-left: 35px;
        margin-right: 35px;
    }
}

.title {
    color: #131f3e;
    font-size: 20px;
    font-weight: 700;
    margin-top: -5px;
}

.labels {
    font-weight: 350;
    color: #000;
    font-size: 14px;
    font-weight: 520;
}

.securityLevel {
    font-size: 5px;
    font-weight: 325;
    margin-left: -30px;
}
