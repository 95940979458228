.titleConfig {
    color: #131F3E;
    font-size: 14px;
}

.radioConfig {
    color: rgba(19, 31, 62, 1);
}

.purpleButton {
    background-color: #4A22D4;
    border: 1px solid #4A22D4;
    color: #FFF;
    font-weight: bold;
    border-radius: 10px;
    margin-left: auto;
    margin-right: auto;
    width: 184px;
    height: 32px;
}

.purpleButton:hover {
    background-color: #FFFFFF;
    color: #4A22D4;
}
