.card {
    width: 400px;
    border-radius: 20px;
    padding: 35px 50px;
}

.title {
    color: #131f3e;
    font-size: 22px;
    font-weight: 700;
}

.noAcc {
    font-size: 14px;
    font-weight: 500;
    white-space: nowrap;
}

.tryKiiper {
    font-size: 14px;
    font-weight: 600;
}

.forgotPass {
    color: #ff007a;
    font-size: 14px;
    font-weight: 600;
}

