.list > li {
    border-radius: 15px;
    background: #fff;
    margin-bottom: 5px;
}

.owner {
    border-radius: 5px;
    background: var(--V100);
    padding: 5px 15px;
    color: #fff;
}

.owner > p {
    color: #fff;
    font-size: 12px;
}

.disabled {
    border-radius: 5px;
    background: #ccc;
    padding: 5px 15px;
}

.disabled > p {
    color: #fff;
    font-size: 12px;
}

.admin {
    border-radius: 5px;
    background: var(--G020);
    padding: 5px 15px;
}

.admin > p {
    color: var(--V090);
    font-size: 12px;
}

.super {
    border-radius: 5px;
    background: #FFB23F;
    padding: 5px 15px;
}

.super > p {
    color: #fff;
    font-size: 12px;
}

.colab {
    border-radius: 5px;
    background: var(--A020);
    padding: 5px 15px;
}

.colab > p {
    color: var(--V090);
    font-size: 12px;
}

.espect {
    border-radius: 5px;
    background: rgba(230, 230, 230, 0.50);
    padding: 5px 15px;
}

.espect > p {
    color: var(--V100);
    font-size: 12px;
}

.lastTime {
    color: #6d6d6d;
    text-align: right;
    font-size: 14px;
}

.listOp > span {
    font-weight: 600;
}

.title {
    padding: 5px 5px 5px 20px;
}

.text {
    text-align: justify;
    font-size: 14px;
}

.text2 {
    text-align: justify;
    font-size: 14px;
}

.temporary {
    border-radius: 3px;
    background: #FFF;
    border: 1px solid #FF007A;
    padding: 4px 15px;
}

.temporary > p {
    color: #FF007A;
    font-size: 12px;
}

.toolTip {
    font-size: 12px;
}

.toolTip > span {
    color: #0ff;
}