.commonWhite {
    color: white;
}

.toolbar {
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: #031851;
}

.icon {
    height: 25px;
    margin-right: 12px;
    color: white;
}

.space {
    padding: 15px 20px;
    position: fixed;
    right: 0;
    top: 0;
}

.button {
    padding: 6px 20px;
}

.Listbutton {
    padding-left: 30px;
    padding-right: 30px;
}

.Listbutton > div > span {
    font-weight: 600;
}

.paperPop {
    padding: 8px 0px;
    border-radius: 5px;
    box-shadow: 10px 10px 15px 0px rgba(3, 24, 81, 0.15);
    background-color: #f8f9f8;
}
