.title {
    color: #131f3e;
    font-size: 18px;
    font-weight: 700;
}

.backLogin {
    font-size: 14px;
    font-weight: 600;
}

.message {
    background-color: rgba(3, 24, 81, 0.05);
    border-radius: 5px;
    padding: 5px 11px;
}

.message > p {
    font-size: 14px;
}
