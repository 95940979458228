.list > li {
    border-radius: 15px;
    background: #fff;
    margin-bottom: 5px;
}

.status {
    color: #6D6D6D;
    text-align: right;
    font-size: 16px;
}

.lastTime {
    color: #B3B3B3;
    text-align: right;
    font-size: 14px;
}

.title {
    padding: 5px;
}

.active {
    border-radius: 5px;
    border: 1px solid #4a22d4;
    padding: 5px 15px;
}

.active > p {
    color: #4a22d4;
    font-size: 12px;
}

.new {
    border-radius: 5px;
    border: 1px solid #B3B3B3;
    padding: 5px 15px;
}

.new > p {
    color: #B3B3B3;
    font-size: 12px;
}

.activeIcon {
    font-size: 14px;
    color: #4a22d4;
}

.newIcon {
    font-size: 14px;
    color: #B3B3B3;
}
